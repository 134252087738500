<template>
    <div class="profile">
        <el-card>
            <el-row :gutter="20" class="operate">
                <el-col :span="4">
                    <el-select v-model="selectType" placeholder="请选择类型" filterable clearable>
                        <el-option v-for="(item, index) in typeList" :key="index" :label="item.name" :value="item.value"></el-option>
                    </el-select>
                </el-col>
                <el-col :span="4">
                    <el-select v-model="selectValueType" placeholder="请选择值类型" filterable clearable>
                        <el-option v-for="(item, index) in valueTypeList" :key="index" :label="item.name" :value="item.value"></el-option>
                    </el-select>
                </el-col>
                <el-col :span="4">
                    <el-input v-model="selectName" placeholder="请输入配置名称" clearable></el-input>
                </el-col>
                <el-button @click="search" icon="el-icon-search" type="primary"></el-button>
            </el-row>
            <el-table v-loading="loading" :data="tableData" style="width: 100%" stripe border>
                <el-table-column fixed="left" prop="id" label="ID" width="100"></el-table-column>
                <el-table-column prop="name" label="名称" width="300"></el-table-column>
                <el-table-column prop="type_name" label="类型" width="200"></el-table-column>
                <el-table-column prop="desc" label="描述"></el-table-column>
                <el-table-column label="状态" width="200">
                    <template slot-scope="scope"><span class="status_text">{{scope.row.status | statusText}}</span></template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="240">
                    <template #default="scope">
                        <router-link :to="`/profile/${scope.row.id}`"><el-button type="primary" size="mini">配置值</el-button></router-link>
                    </template>
                </el-table-column>
            </el-table>

            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="params.page" :page-sizes="[5, 10, 20, 50, 100, 200]" :page-size="params.page_size" layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>

        </el-card>
    </div>
</template>

<script>
    import {getProfileList, getProfileTypeAll} from '@api';

    export default {
        name: 'Profile',
        data() {
            return {
                params: { // 请求分页参数
                    page: 1,
                    page_size: 10
                },
                total: 0, // 数据总条数
                tableData: [], // 表格数据
                selectName: '', // 搜索名称
                selectType: '', // 搜索类型
                selectValueType: '', // 搜索值类型
                typeList: [], // 类型数据
                valueTypeList: [{name:"单值", value:1}, {name: "范围值", value: 2}] // 值类型数据
            }
        },
        created() {
            this.getData(this.params);
            getProfileTypeAll().then(res => this.typeList = res.data);
        },
        filters: {
            statusText(status) {
                if (status > 0) {
                    return '已审核';
                }
                return '未审核';
            }
        },
        methods:{
            search() { // 搜索
                const {selectName, selectType, selectValueType} = this;
                this.params.name = selectName;
                this.params.type = selectType;
                this.params.value_type = selectValueType;
                this.getData(this.params);
            },
            handleCurrentChange(val) { // 获取页数
                this.params.page = val;
                this.getData(this.params);
            },
            handleSizeChange(val) { // 获取每页条数
                this.params.page_size = val;
                this.getData(this.params);
            },
            getData(params) {
                this.loading = true;
                getProfileList(params).then(res => {
                    this.loading = false;
                    const {data, count} = res.data;
                    this.total = count;
                    this.tableData = data;
                }).catch(() => {
                    this.loading = false;
                })
            }
        },
    }
</script>

<style lang="less" scoped>

</style>